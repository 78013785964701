import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./Components/Common/Header/Header";
import Footer from "./Components/Common/Footer/Footer";
import HomeRoute from "./Components/HomePage/HomeRoute";
import Search from "./Components/HomePage/Search";
//Sector Detail Pages
import SectorDetail from "./Components/SectorsPage/SectorDetail";

//About Pages
import AboutUs from "./Components/AboutPages/Overview";
import MissionVisionValue from "./Components/AboutPages/MissinVision";
import LeaderShipTeam from "./Components/AboutPages/LeaderShipTem";
import LeaderShipDetail from "./Components/AboutPages/LeaderShipDetail";
import BoardDirectors from "./Components/AboutPages/BoardDirectors";
import TataValues from "./Components/AboutPages/TataValue";
import PoliciesChartes from "./Components/AboutPages/PoliciesCharters";

//Blogs
import Blogs from "./Components/Blogs/Blogs";
import BlogDetails from "./Components/Blogs/BlogDetail";
//Services Certification
import CertificationPage from "./Components/TQServices/Certifications/Certification";
import MSCertification from "./Components/TQServices/Certifications/MSCertification";
import FoodCertification from "./Components/TQServices/Certifications/FoodCertification";
import YogaCertification from "./Components/TQServices/Certifications/YogCertification";
import AyushCertification from "./Components/TQServices/Certifications/AyushCertification";
import OrganicCertification from "./Components/TQServices/Certifications/Organic";
import IBSCertification from "./Components/TQServices/Certifications/IBS";
import SafetyAudits from "./Components/TQServices/Certifications/SafetyAudits";
import Warehouse from "./Components/TQServices/Certifications/Warehousing";
import INDGAPCertification from "./Components/TQServices/Certifications/INDGAP";
import GlobalCertification from "./Components/TQServices/Certifications/Global";
import RMCCertification from "./Components/TQServices/Certifications/RMC";
import UASCertification from "./Components/TQServices/Certifications/UAS";
import CertificationDownload from "./Components/TQServices/Certifications/Download";
//Project Services
import TQAllServices from "./Components/TQServices/AllServices";
import ServicesOverView from "./Components/TQServices/ProjectServices/OverView";
import RealEstate from "./Components/TQServices/ProjectServices/RealEstate";
import Hospitality from "./Components/TQServices/ProjectServices/Hospitality";
import Healthcare from "./Components/TQServices/ProjectServices/Healthcare";
import IndustrialManufacturing from "./Components/TQServices/ProjectServices/IndustrialManufacturing";
import Warehousing from "./Components/TQServices/ProjectServices/Warehousing";
import IPMServices from "./Components/TQServices/ProjectServices/IPMServices";
import BIMServices from "./Components/TQServices/ProjectServices/BIMServices";
import PMOServices from "./Components/TQServices/ProjectServices/PMOServices";
import DesignEngineering from "./Components/TQServices/ProjectServices/DesignEngineering";
//Assurance Services
import AssuranceServices from "./Components/TQServices/AssuranceService/OverView";
import SupplierQuality from "./Components/TQServices/AssuranceService/SupplierQuality";
import FieldQuality from "./Components/TQServices/AssuranceService/FieldQuality";
import Expediting from "./Components/TQServices/AssuranceService/Expediting";
import DeskServices from "./Components/TQServices/AssuranceService/DeskServices";
import AIM from "./Components/TQServices/AssuranceService/AIM";
import LES from "./Components/TQServices/AssuranceService/LES";
import POEMs from "./Components/TQServices/AssuranceService/P-OEMs";
import SafetyServices from "./Components/TQServices/AssuranceService/SafetyServices";
import Download from "./Components/TQServices/AssuranceService/Download";

//Careers
import Careers from "./Components/Careers/Careers";
import JobDetail from "./Components/Careers/JobDetail";
import CareerForm from "./Components/Careers/JobForm";
//Contact
import ContactUs from "./Components/ContactPage/ContactUs";

import KeyProjects from "./Components/AboutPages/KeyProjects";
import KeyProjectDetail from "./Components/TQServices/ProjectServices/KeyProjectDetail.";
//BAck end Admin
import PrivateRoute from "./Admin/PrivateRoute";
import LoginForm from "./Admin/LoginForm";
import ForgetPassword from "./Admin/ForgetPassword";
import RegisterForm from "./Admin/RegisterForm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Admin Section
import Layout from "./Admin/adminsection/Layout";
import Content from "./Admin/adminsection/Content";
import HomeRoutes from "./Admin/adminsection/homesection/Homeroutes";
import MenuRoutes from "./Admin/adminsection/menusection/MenuManagement";
import AboutRoutes from "./Admin/adminsection/aboutUs/AboutUsroutes";
import CarrerRoutes from "./Admin/adminsection/carrerPage/Carrerroutes";
import MissionRoutes from "./Admin/adminsection/missionsection/Missionroutes";
import MemberRoutes from "./Admin/adminsection/MemberSection/LeadershipTeam";
import SocialMediaLinkManagerRoutes from "./Admin/adminsection/SocialAddress/SocialMediaLinkManager";
import ContactManagerRoutes from "./Admin/adminsection/Contactform/ContactManager";
import KeyProjectsRoutes from "./Admin/adminsection/keyProject/KeyProjectRoutes";
import BlogManagerRoutes from "./Admin/adminsection/blogSection/BlogsManager";
import PoliciesManagerRoutes from "./Admin/adminsection/PoliciesSection/PoliciesManager";
import ProjectServiceSectionRoutes from "./Admin/adminsection/ProjectService/ProjectServiceRoute";
import CustmoresManagerRoutes from "./Admin/adminsection/CustmoresSection/Custmore";
import DownloadManagerRoutes from "./Admin/adminsection/DownloadSection/Downloadroutes";
import ContactUsRoutes from "./Admin/adminsection/ContactUs";
import JobListRoutes from "./Admin/adminsection/jobSection/jobList";
import MapLocationRoutes from "./Admin/adminsection/MapSection/Mapdata";
import JobApplicationListRoutes from "./Admin/adminsection/jobSection/jobApplicationList";
import PrivacyPolicy from "./Components/PolicyPages/PrivacyPolicy";
import CookiePolicy from "./Components/PolicyPages/CookiePolicy";
import DisclamierPolicy from "./Components/PolicyPages/Disclamier";

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const CommonHeaderFooter = () => {
    return (
      <>
        <Header />
        <Outlet />
        <ToastContainer />
        <Footer />
      </>
    );
  };

  return (
    <Routes>
      <Route path="/" element={<CommonHeaderFooter />}>
        <Route path="/" element={<HomeRoute />}></Route>
        <Route path="/sector/:url" element={<SectorDetail />}></Route>
        {/* <Route path='/about-us' element={<AboutUs />}></Route> */}
        <Route path="/mission-vision" element={<MissionVisionValue />}></Route>
        <Route path="/about-us" element={<LeaderShipTeam />}></Route>
        <Route
          path="/senior-leadership-detail"
          element={<LeaderShipDetail />}
        ></Route>
        <Route path="/board-directors" element={<BoardDirectors />}></Route>
        <Route path="/key-projects" element={<KeyProjects />}></Route>
        <Route path="/key-projects/:id" element={<KeyProjectDetail />}></Route>
        <Route path="/tata-values" element={<TataValues />}></Route>
        <Route
          path="/policies-and-charters"
          element={<PoliciesChartes />}
        ></Route>
        <Route path="/search" element={<Search />}></Route>
        <Route path="/blogs" element={<Blogs />}></Route>
        <Route path="/blog-detail/:link" element={<BlogDetails />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/services" element={<TQAllServices />}></Route>
        <Route path="/Certification" element={<CertificationPage />}></Route>
        <Route
          path="/management-system-certification"
          element={<MSCertification />}
        ></Route>
        <Route
          path="/food-certification"
          element={<FoodCertification />}
        ></Route>
        <Route
          path="/yoga-certification"
          element={<YogaCertification />}
        ></Route>
        <Route path="/project-services" element={<ServicesOverView />}></Route>
        <Route
          path="/product-certification"
          element={<AyushCertification />}
        ></Route>
        <Route
          path="/organic-certification"
          element={<OrganicCertification />}
        ></Route>
        <Route
          path="/indgap-certification"
          element={<INDGAPCertification />}
        ></Route>
        <Route
          path="/global-certification"
          element={<GlobalCertification />}
        ></Route>
        <Route path="/rmc-certification" element={<RMCCertification />}></Route>
        <Route path="/uas-certification" element={<UASCertification />}></Route>
        <Route
          path="/certification-downloads"
          element={<CertificationDownload />}
        ></Route>
        <Route path="/she-services" element={<IBSCertification />}></Route>
        <Route path="/safety-audits" element={<SafetyAudits />}></Route>
        <Route path="/real-state" element={<RealEstate />}></Route>
        <Route path="/hospitality" element={<Hospitality />}></Route>
        <Route path="/healthcare" element={<Healthcare />}></Route>
        <Route path="/industrial" element={<IndustrialManufacturing />}></Route>
        <Route path="/warehoues" element={<Warehousing />}></Route>
        <Route
          path="/integrated-project-management-services"
          element={<IPMServices />}
        ></Route>
        <Route path="/bim-and-vdc-services" element={<BIMServices />}></Route>
        <Route path="/pmo-services" element={<PMOServices />}></Route>
        <Route
          path="/design-and-engineering-reviews"
          element={<DesignEngineering />}
        ></Route>
        <Route
          path="/assurance-service"
          element={<AssuranceServices />}
        ></Route>
        <Route path="/download" element={<Download />}></Route>
        <Route path="/supplier-quality" element={<SupplierQuality />}></Route>
        <Route path="/field-quality" element={<FieldQuality />}></Route>
        <Route path="/expediting" element={<Expediting />}></Route>
        <Route path="/desk-services" element={<DeskServices />}></Route>
        <Route path="/asset-integrity-management" element={<AIM />}></Route>
        <Route path="/lenders-engineer-services" element={<LES />}></Route>
        <Route path="/p-oems" element={<POEMs />}></Route>
        <Route
          path="/safety-as-a-services"
          element={<SafetyServices />}
        ></Route>
        <Route path="/warehousing" element={<Warehouse />}></Route>
        <Route path="/Login" element={<LoginForm />}></Route>
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/register-user" element={<RegisterForm />} />
        <Route path="/careers" element={<Careers />}></Route>
        <Route path="/job-position/:jobId" element={<JobDetail />}></Route>
        <Route path="/apply-job" element={<CareerForm />}></Route>
        <Route path='/privacy-policy' element={<PrivacyPolicy />}></Route>
        <Route path='/cookie-policy' element={<CookiePolicy />}></Route>
        <Route path='/disclaimer' element={<DisclamierPolicy />}></Route>
      </Route>
      {/* //Admin routes */}

      <Route path="/" element={<PrivateRoute element={<Layout />} />}>
        <Route
          path="admin/menu"
          element={<PrivateRoute element={<MenuRoutes />} />}
        />
        <Route
          path="admin/home-page"
          element={<PrivateRoute element={<HomeRoutes />} />}
        />
        <Route
          path="admin/about-page"
          element={<PrivateRoute element={<AboutRoutes />} />}
        />
        <Route
          path="admin/carrer"
          element={<PrivateRoute element={<CarrerRoutes />} />}
        />
        <Route
          path="admin/mission-value"
          element={<PrivateRoute element={<MissionRoutes />} />}
        />
        <Route
          path="admin/team-member"
          element={<PrivateRoute element={<MemberRoutes />} />}
        />
        <Route
          path="admin/socialmedia"
          element={<PrivateRoute element={<SocialMediaLinkManagerRoutes />} />}
        />
        <Route
          path="admin/address"
          element={<PrivateRoute element={<ContactManagerRoutes />} />}
        />
        <Route
          path="admin/key-projects"
          element={<PrivateRoute element={<KeyProjectsRoutes />} />}
        />
        <Route
          path="admin/blog-page"
          element={<PrivateRoute element={<BlogManagerRoutes />} />}
        />
        <Route
          path="admin/policie-page"
          element={<PrivateRoute element={<PoliciesManagerRoutes />} />}
        />
        <Route
          path="admin/custmores"
          element={<PrivateRoute element={<CustmoresManagerRoutes />} />}
        />
        <Route
          path="admin/downloads"
          element={<PrivateRoute element={<DownloadManagerRoutes />} />}
        />
        <Route
          path="admin/services"
          element={<PrivateRoute element={<ProjectServiceSectionRoutes />} />}
        />
        <Route
          path="admin/contact"
          element={<PrivateRoute element={<ContactUsRoutes />} />}
        />
        <Route
          path="admin/jobs"
          element={<PrivateRoute element={<JobListRoutes />} />}
        />
        <Route
          path="admin/job-applications"
          element={<PrivateRoute element={<JobApplicationListRoutes />} />}
        />
        <Route
          path="admin/map-locations"
          element={<PrivateRoute element={<MapLocationRoutes />} />}
        />
        <Route path="/" element={<Content data="Default Data" />} />
      </Route>
    </Routes>
  );
}

export default App;
