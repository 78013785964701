import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css';
import { Link, useLocation  } from "react-router-dom";
import TQRank from "../HomePage/TQRank";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getTQTeams, getAboutUsAPI, getSubsidiariesAffiliatesAPI, getTQAddressAPI } from '../../services/routes.services';
import { Image_BASE_URL } from '../../services/constants';
import constants from "../../services/constants";
import parse from 'html-react-parser';
import Loader from "../Common/PageLoader/LoadingSpinner";
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      centerMode: true,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      centerMode: true,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      centerMode: true,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      centerMode: false,
    },
  };

const LeaderShipTeam = ()=>{
    //math Path
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 464);
      };
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 
    

    const [teamMembers, setTeamMembers] = useState([]);
    const [aboutContent, setAboutContent] = useState([]);
    const [infraSlide, setInfraSlide] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addressData, setAddressData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [teamsResponse, aboutResponse, subsidiariesResponse, addressResponse] = await Promise.all([
                    getTQTeams('Senior'),
                    getAboutUsAPI(),
                    getSubsidiariesAffiliatesAPI(),
                    getTQAddressAPI('About')
                ]);

                setTeamMembers(teamsResponse.data.teams);
                setAboutContent(aboutResponse.data.about[0]);
                setInfraSlide(subsidiariesResponse.data.infra);
                setAddressData(addressResponse.data.addresses);
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    if (loading) {
        return <Loader />;
    }
    return(
        <div className={'leaderPage ' + Styles.leaderBlock}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
               {BannerImage && <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.aboutContent}>
                <img src={require('../../assest/images/about-img.png')} className={Styles.aboutImg} />
                <Container>
                    <Row>
                        <Col>
                            {aboutContent &&
                                <div className={Styles.aboutElem}>
                                    <h3>About Us</h3>
                                    {parse(`${aboutContent.description}`)}
                                    {parse(`${aboutContent.description1}`)}
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
                <div className={Styles.leaderRank}>
                    <Container>
                        <Row>
                            <Col>
                                <TQRank />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.seniorTeam}>
                <Container>
                    <Row>
                        <Col>
                            <h3 className={Styles.centerAlight}>Senior Leadership Team</h3>
                            <div className={Styles.seniorContainer}>
                                {teamMembers &&
                                    teamMembers.map((seniorTeam)=>{
                                        return(
                                            <div className={Styles.seniorElem} key={seniorTeam.order}>
                                                <div className={Styles.seniorElemSpace}>
                                                    <figure>
                                                        {seniorTeam.image && <img src={`${ constants.Image_BASE_URL}${seniorTeam.image}`} />}
                                                    </figure>
                                                    <div className={Styles.seniorDetail}>
                                                        <h6>{seniorTeam.name} <span>{seniorTeam.position}</span></h6>
                                                        {/* <Link to={seniorTeam.link} className={BtnStyle.roundBtnSm + ' ' + BtnStyle.RoundRed}><img src={require('../../assest/images/White_Arrow.png')} alt="Arrow" /></Link> */}
                                                    </div>
                                                    {/* <div className={Styles.expendLeader}>
                                                        <Link to='/'>Click to Expend</Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.branchAddress}>
                <Container>
                    <Row>
                        <Col>
                            <h4 className={Styles.centerAlight}>Branch Offices</h4>
                            <div className={Styles.addressContainer}>
                                {addressData &&
                                    addressData.sort((a, b) => a.order - b.order).map((item)=>{
                                        return(
                                            <div className={Styles.addressElem} key={item._id}>
                                                <h5>{item.title}</h5>
                                                <span>{item.city}</span>
                                                <p>{parse(`${item.addressName}`)}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.AffiliatesSec}>
                <Container>
                    <Row>
                        <Col>
                            <h3 className={Styles.centerAlight}>Subsidiaries & Affiliates</h3>
                        </Col>
                    </Row>
                </Container>
                <div className={Styles.AffiliOwl}>
                    <Carousel centerMode={!isMobile} focusOnSelect={true} autoPlaySpeed={2000} transitionDuration={500} responsive={responsive} autoPlay={false} infinite={true} arrows={true} containerClass="leaderPageOwl arrowStyle">
                        {infraSlide &&
                            infraSlide.map((item)=>{
                                return(
                                    <div className={Styles.slideContainer} key={item.order}>
                                        <figure>
                                          {item.image &&  <img src={constants.Image_BASE_URL+""+item.image} />}
                                        </figure>
                                        <div className={Styles.SlideDetail}>
                                            <h5>{item.title}</h5>
                                            {parse(`${item.description}`)}
                                        </div>
                                    </div>
                                )
                            })
                        }          
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default LeaderShipTeam;